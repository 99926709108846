import { Form, Input, message } from "antd"
import React from "react"
import ContactButton from "./contactButton"
import emailjs from "emailjs-com"

const { TextArea } = Input

function EmailForm() {
  const sendEmail = e => {
    emailjs
      .send(
        "service_nblag31",
        "template_pl4q3uj",
        e,
        "user_TmjWvWoB269vs4SyVR5rL"
      )
      .then(
        result => {
          message.success(result.text)
        },
        error => {
          message.error(error.text)
        }
      )
    if (typeof window != "undefined") {
      window.location.reload()
    }
  }

  return (
    <Form
      onFinish={sendEmail}
      style={{ fontFamily: "Source Sans Pro" }}
      layout="vertical"
    >
      <Form.Item
        rules={[{ required: true, message: "Digite seu nome." }]}
        label="Nome"
        name="name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Digite um e-mail" }]}
        label="E-mail"
        name="email"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Dê um assunto para o seu e-mail" }]}
        label="Assunto"
        name="subject"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Digite uma mensagem" }]}
        label="Mensagem"
        name="message"
      >
        <TextArea autoSize={{ minRows: 6 }} />
      </Form.Item>
      <Form.Item className="text-center">
        <ContactButton submit type="primary" htmlType="submit" text="Enviar" />
      </Form.Item>
    </Form>
  )
}

export default EmailForm
