import React from "react"
import EmailForm from "../components/emailForm"
import Layout from "../components/layout"
import Seo from "../components/seo"

function Contato() {
  return (
    <Layout titlePage="Contato" titleMargin="46">
      <Seo
        keywords={[`Inovação`, `Usinagem`, `Ferramentaria`]}
        title="Contato"
      />
      <div className="py-24 mx-2 lg:mt-0">
        <center className="mb-10 grid grid-cols-1 md:grid-cols-3">
          <div>
            <i
              style={{ fontSize: "6rem", color: "rgb(0, 8, 51)" }}
              className="bx bx-phone-call mb-6"
            ></i>
            <h2 className="mb-6">Ligue para nós</h2>
            <a
              href="tel:554734738147"
              style={{ fontFamily: "Source Sans Pro" }}
              className="text-2xl mb-2"
            >
              <h1 className="text-center text-3xl md:text-4xl">
                (47) 3473-8147
              </h1>
            </a>
          </div>
          <h3 style={{ fontWeight: "normal" }} className="my-24">
            ou, se preferir...
          </h3>
          <div>
            <div>
              <i
                style={{ fontSize: "6rem", color: "rgb(0, 8, 51)" }}
                className="bx bxl-whatsapp mb-6"
              ></i>
              <h2 className="mb-6">Mande uma mensagem</h2>
              <a
                className="text-2xl mb-2"
                href="https://wa.me/5547996872019"
                rel="noopener noreferrer"
                target="_blank"
              >
                <h4 className="text-md -mb-0">Administrativo:</h4>
                <h2 className="text-center text-3xl md:text-3xl">
                  (47) 99687-2019
                </h2>
              </a>
              <a
                className="text-2xl mb-2"
                href="https://wa.me/5547996527101"
                rel="noopener noreferrer"
                target="_blank"
              >
                <h4 className="text-md -mb-0">Diretor Técnico:</h4>
                <h2 className="text-center text-3xl md:text-3xl">
                  (47) 99652-7101
                </h2>
              </a>
            </div>
          </div>
        </center>
        <center>
          <h3 style={{ fontWeight: "normal" }} className="my-24">
            ou, ainda...
          </h3>
          <div>
            <div>
              <i
                style={{ fontSize: "6rem", color: "rgb(0, 8, 51)" }}
                className="bx bx-envelope mb-6"
              ></i>
              <h2 className="mb-6">Mande um e-mail</h2>
              <a
                className="text-2xl mb-2"
                href="mail:administrativo@inovacaousinagem.com.br"
                rel="noopener noreferrer"
                target="_blank"
              >
                <h2 className="text-center text-xl">
                  administrativo@inovacaousinagem.com.br
                </h2>
              </a>
            </div>
          </div>
        </center>
        <EmailForm />
        <center>
          <h2 className="my-20">Venha nos vistar</h2>
          <div className="grid lg:grid-cols-2 gap-6">
            <div>
              <iframe
                width="100%"
                title="InovacaoLocationMap"
                height="360"
                frameBorder="0"
                style={{ border: 0, display: "block" }}
                src={`https://maps.google.com/maps?q=${encodeURI(
                  "Rua Willy Schossland, 396"
                )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                allowFullScreen=""
              />
            </div>
            <div
              style={{ fontFamily: "Source Sans Pro" }}
              className="text-xl text-left mb-2"
            >
              <p className="my-1">Rua Willy Schossland, 396</p>
              <p className="my-1">Bairro Iririú</p>
              <p className="my-1">CEP 89224-061</p>
              <p className="my-1">Joinville, SC</p>
            </div>
          </div>
        </center>
      </div>
    </Layout>
  )
}

export default Contato
